<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <!-- ---- main card ---- -->        
        <v-card
          elevation="4"
          outlined
          style="width:800px"
        >
          <v-card-title class="headline grey lighten-2">
            Log ud
          </v-card-title>
          <v-card-text>
            <h3 class="pt-3">
              Er du sikker på, at du vil logge ud af TPA Portalen?
            </h3>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              outlined
              color="primary"
              @click="cancel"
            >
              Annuller
            </v-btn>
            <v-btn
              color="secondary"
              @click="logoff"
            >
              Log ud af systemet
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {  
  name: 'Logoff',
  data() {
      return {
      model: null,
      //view only
      loaded: false
    }
  },
  computed: {
    account() {
      return this.$AuthService.accountObj;
    },
    name() {
      return this?.account?.name ?? "Du";
    },
  },
  methods: {
    logoff: function() {
      this.$AuthService.logoff();
    },
    cancel: function() {
      this.$router.replace('/');
    }
  }
}
</script>